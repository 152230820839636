// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=DynaPuff:wght@400;500;700&display=swap");

:root {
   --text-color: #484848;
   --default-layout-width: 1170px;
}

* {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}

body {
   font-family: "Cabin", sans-serif !important;
   // font-family: "DynaPuff", cursive !important;
   font-size: 14px;
   font-weight: 400;
   line-height: 1.5;
   text-rendering: optimizeSpeed;
   color: var(--text-color);
}

li {
   list-style: none;
}

input,
button,
[tabindex] {
   border: none;
   outline: none;
}

// .container {
//    max-width: var(--default-layout-width);
//    margin: 0 auto;
//    padding: 0 15px;
// }



//pc
@media only screen and (min-width: 1024px) {
 
}
//tablet
@media only screen and (min-width: 740px) and (max-width: 1023px) {
 
}
//mobile
@media only screen and (max-width: 740px) {
 
   
}
