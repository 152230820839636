.header-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    background: #f1f1f1;
    //  position: fixed;
    //  top: 0;
    //  left: 0;
    //  right: 0;
}

.logo img {
    width: 253px;
    height: 70px;
    vertical-align: middle;
}

.menu-item a {
    display: block;

    font-size: 17px;
    font-weight: 600;
    color: #3d4246;
    text-transform: uppercase;
}

.header-right {
    display: flex;
    align-items: center;
}

.search {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 190px;
    background: #ffffff;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0 0 5px 0 rgb(102 102 102 / 40%);

    &-input {
        width: 100%;
        height: 38px;
        padding: 0 10px;
    }

    &-btn {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        cursor: pointer;
    }
}

.user-btn {
    font-size: 22px;
    color: #3d4246;
    margin: 0 10px;
}

.shopping-btn {
    color: #3d4246;

    font-size: 22px;
}

@media only screen and (min-width: 1280px) {
    .bars {
        display: none;
    }
}

//pc
@media only screen and (min-width: 1024px) {
}
//tablet
@media only screen and (min-width: 740px) and (max-width: 1023px) {
}
//mobile
@media only screen and (max-width: 740px) {
}
